import React from "react";

import "./Plugins.css";

import PluginCard from "../components/PluginCard";
import DownloadButton from "../components/DownloadButton";

const pandamoniumImg = require("../assets/images/interface.png");
const partyPandaImg = require("../assets/images/party-panda-interface.png");
const bambooForestImg = require("../assets/images/bamboo-forest-interface.png")

function Plugins() {
  return (
    <div className="plugins-content">
      <h1 className="welcome-text">Welcome to Cool Panda Plugins</h1>
      <div className="download-suite-wrapper">
        <DownloadButton downloadTitle="Download Full Suite" releaseUrl="https://github.com/mattanikiej/cool-panda-plugins-full-suite/releases"/>
      </div>

      <h2>Current Plugins:</h2>

      <PluginCard
        src={bambooForestImg}
        title="Bamboo Forest"
        repo="https://github.com/mattanikiej/bamboo-forest-ghost-chorus"
        description="For a twinkling delay and a spacious chorus, the Bamboo Forest can give a surreal effect to your audio. Increasing Ghost decreases the initial chorus effect, while keeping it intact in the delay signal. This creates a cool vibe as if a chorus of ghosts is helping you along."
        releaseUrl="https://github.com/mattanikiej/bamboo-forest-ghost-chorus/releases"
        demoSrc="https://www.youtube.com/embed/C1OoC6Dh8qE?si=NRXJjhNNxMJcaVSO"
        downloadTitle="Download Installer"
      />

      <PluginCard
        src={partyPandaImg}
        title="Party Panda"
        repo="https://github.com/mattanikiej/party-panda-univibe"
        description="For a groovy feel, or a vibe like no other, Party Panda can do it all. Its unique character is more than your typical modulation. For both ragers and kickbacks, Party Panda will fit your vibe perfectly!"
        releaseUrl="https://github.com/mattanikiej/party-panda-univibe/releases"
        demoSrc="https://www.youtube.com/embed/yq-T4vw3dbU?si=C1xox5eVtzNpCouE"
        downloadTitle="Download Installer"
      />

      <PluginCard
        src={pandamoniumImg}
        title="Pandamonium"
        repo="https://github.com/mattanikiej/pandamonium-fuzz"
        description="The Pandamonium is a fuzz plugin that is sure to offer all of the distortion you could ever want! Featuring 3 fuzz modes, and 4 knobs to dial in that perfect grizzly tone you are looking for. Try it free now!"
        releaseUrl="https://github.com/mattanikiej/pandamonium-fuzz/releases"
        demoSrc="https://www.youtube.com/embed/5FAq3uDZJW8?si=OhDeb8QQ3O4hameS"
        downloadTitle="Download Installer"
      />
    </div>
  );
}

export default Plugins;
