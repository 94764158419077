import React from 'react'

import logo from "../assets/images/right-facing-logo.svg"

import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
        <p>Designed and developed by Cool Panda Software</p>
        <a className="logo-container" href="https://coolxpanda.com" target="_blank" rel="noopener noreferrer">
            <img alt="cool panda logo" src={logo} />
        </a>
    </div>
  )
}

export default Footer