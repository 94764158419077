import React from "react";

import "./PluginCard.css";

import DownloadButton from "./DownloadButton";

function PluginCard(props) {

    return (
        <div className="plugin-card-container">
            <h2 className="plugin-title">{props.title}</h2>
            <p className="image-container">
                <img alt="plugin interface" src={props.src}/>
            </p>
            
            <p className="plugin-description">
                {props.description}
            </p>

            <div className="download-buttons-container">
                <DownloadButton releaseUrl={props.releaseUrl} downloadTitle={props.downloadTitle}/>
            </div>

            <a href={props.repo} className="contribute-link">Source Code</a>
            
            <p className="demo">
                <iframe width="100%" height="100%" src={props.demoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </p>
        </div>
    );
}

export default PluginCard;