import { Routes, Route } from "react-router-dom";

import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';

import Plugins from './pages/Plugins';
import About from './pages/About';

function App() {
  return (
    <div className="App">
      <section className='app-content'>
        <Header />
        <Routes>
              <Route path="/" element={<Plugins />} />
              <Route path="about" element={<About />} />
          </Routes>
      </section>
        <Footer />
    </div>
  );
}

export default App;
