import React from 'react'

import "./DownloadButton.css";

function DownloadButton(props) {
  return (
    <a className='button-container' href={props.releaseUrl} target="_blank" rel="noopener noreferrer">
      <button className='download-button'>
          <p className='button-text'>{props.downloadTitle}</p>
      </button>
    </a>
  )
}

export default DownloadButton