import React from "react";

import "./About.css"

import logo from "../assets/images/right-facing-logo.svg"

function About() {
  return (
    <div className="about-content">
      <p className="about-description">
        At <span style={{fontFamily: "Komikax"}}>Cool Panda Plugins</span> from <span style={{fontFamily: "Komikax"}}>Cool Panda Software</span>, we believe in free and
        interesting plugins available for everyone. The music industry is
        unfortunately notorious for being exploitative, and it leads to many of
        the exciting plugins to be behind massive paywalls. To us though, music
        has always been collaborative and something that can (and should) be
        shared with everyone. We want to create interesting effects that are
        released as open source projects for everyone to not only be able to
        use, but also build upon and improve. When we are able to create ideas
        that we have together, everyone wins.
      </p>

      <section className="contact-section">
        <a className="contact-image-wrapper" href="https://coolxpanda.com" target="_blank" rel="noopener noreferrer">
            <img alt="cool panda logo" src={logo} />
        </a>
        <div className="contact-text-wrapper">
            <p className="about-contact-text">Cool Panda Software</p>
            <p className="about-contact-text">coolpandasoftware@gmail.com</p>
        </div>
      </section>
      
    </div>
  );
}

export default About;
