import React from "react";
import {Link} from 'react-router-dom'

import "./Header.css";

function Header() {

    return (
        <header className="header-container">
            <Link to="/" className="header-title">Cool Panda Plugins</Link>
            <nav className="nav-bar">
                <Link to="/" className="nav-title">Plugins</Link>
                <Link to="about" className="nav-title">About</Link>
            </nav>
            
        </header>
    );
}

export default Header;
